<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout column justify-center align-center fill-height>
		<w-flex shrink headline mb-5>{{ $t('mobileapp.titles.create', { appName: application.title }) }}</w-flex>
		<w-flex grow>
			<w-layout row justify-center align-center fill-height>
				<v-stepper v-model="stepIndex" class="elevation-0">
					<template v-for="(step, index) in steps">
						<v-stepper-content v-if="stepIndex == index + 1" :key="index" :step="index + 1">
							<component
								:is="step.component"
								v-model="application"
								:start-with-last="subStartWithLast"
								:step-name="step.stepName"
								@previous="subPrevious"
								@next="subNext"
								@unvalidated="step.isValid = false"
								@validated="step.isValid = true"
							/>
						</v-stepper-content>
					</template>
				</v-stepper>
			</w-layout>
		</w-flex>
		<w-flex shrink>
			<w-layout row justify-center align-center>
				<w-btn v-if="steps[stepIndex - 1].canGoBack" flat round color="primary" @click="previous">
					<w-layout row justify-center align-center>
						<w-icon class="mr-2">arrow_back</w-icon>
						<w-flex v-t="'actions.go_back'" shrink />
					</w-layout>
				</w-btn>
				<w-btn :disabled="!steps[stepIndex - 1].isValid" round color="primary" @click="steps[stepIndex - 1].action()">
					<w-layout row justify-center align-center>
						<w-icon class="mr-2">{{ steps[stepIndex - 1].actionIcon }}</w-icon>
						<w-flex shrink v-text="steps[stepIndex - 1].actionText" />
					</w-layout>
				</w-btn>
			</w-layout>
		</w-flex>
		<w-dialog v-model="launchDialog" warning max-width="66vh" :title="$t('mobileapp.actions.launch_creation')">
			<w-layout column justify-center align-center>
				<w-flex v-html="$t('mobileapp.actions.confirm_launch_creation')" />
				<w-flex>
					<w-layout row justify-center align-center>
						<w-flex>
							<w-btn icon="cancel" @click="launchDialog = false">{{ $t('actions.cancel') }}</w-btn>
						</w-flex>
						<w-flex>
							<w-btn icon="build" @click="doCreate">{{ $t('actions.confirm') }}</w-btn>
						</w-flex>
					</w-layout>
				</w-flex>
			</w-layout>
		</w-dialog>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'MobileAppCreationWizard',
	mixins: [SuperAdminModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			stepIndex: 1,
			steps: [
				{
					component: () => import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppStepAppleAppStore'),
					isValid: false,
					endStop: true,
					canGoBack: true,
					action: this.create,
					actionText: this.$t('mobileapp.actions.launch_creation'),
					actionIcon: 'build',
					stepName: 'ios'
				},
				{
					component: () => import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppStepGooglePlayStore'),
					isValid: false,
					endStop: true,
					canGoBack: false,
					action: this.next,
					actionText: this.$t('continue'),
					actionIcon: 'skip_next',
					stepName: 'android'
				},
				{
					component: () => import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppStepBitrise'),
					isValid: false,
					endStop: true,
					canGoBack: true,
					action: this.update,
					actionText: this.$t('continue'),
					actionIcon: 'build',
					stepName: 'bitrise'
				},
				{
					component: () => import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppStepFinalize'),
					isValid: false,
					endStop: true,
					canGoBack: false,
					action: this.end,
					actionText: this.$t('mobileapp.actions.end_creation'),
					actionIcon: 'pending',
					stepName: 'finalize'
				}
			],
			subStartWithLast: false,
			launchDialog: false
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	},
	created: function () {
		this.initializeStepIndex()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.MOBILE_APP_CREATED, action: this.next }]
		},
		subPrevious: function () {
			if (!this.steps[this.stepIndex - 1].startStop) {
				this.previous()
			}
		},
		subNext: function () {
			if (!this.steps[this.stepIndex - 1].endStop) {
				this.next()
			}
		},
		previous: async function () {
			this.subStartWithLast = true
			if (this.stepIndex > 1) {
				this.service.updateMobileAppWizard(this.application.id, { step_name: this.steps[this.stepIndex - 2].stepName, step_index: 1 }).then(wizard => {
					this.application.wizard = wizard
					this.stepIndex--
				})
			} else {
				this.$emit('back')
			}
		},
		next: async function () {
			this.subStartWithLast = false
			if (this.steps[this.stepIndex - 1].isValid && this.stepIndex < this.steps.length) {
				this.service.updateMobileAppWizard(this.application.id, { step_name: this.steps[this.stepIndex].stepName, step_index: 1 }).then(wizard => {
					this.application.wizard = wizard
					this.stepIndex++
				})
			}
		},
		create: function () {
			this.launchDialog = true
		},
		doCreate: function () {
			this.$emit('build')
			this.launchDialog = false
		},
		update: function () {
			this.$emit('deploy')
			this.next()
		},
		end: function () {
			this.service.deleteMobileAppWizard(this.application.id)
			this.application.wizard = null
			this.$emit('end')
		},
		initializeStepIndex: function () {
			if (this.application?.wizard) {
				this.stepIndex = this.steps.map(step => step.stepName).indexOf(this.application.wizard.step_name) + 1
			} else {
				this.stepIndex = 1
			}
		}
	}
}
</script>
